<template>
  <ReportsComponent
    :urls="urls"
    :mandatory-filter="[
      {
        name: 'report_date_month_range',
        title: 'Date range',
        valueFieldName: '.select',
      },
    ]"
  />
</template>

<script>
import ReportsComponent from "@/own/components/reports/ReportComponent";

export default {
  name: "ReportShippedByCustomer",
  components: {
    ReportsComponent,
  },
  computed: {
    urls: () => ({
      data_url: "/reports/reportShippedByCustomer",
      show_url: "/reports/reportShippedByCustomer/show",
    }),
  },
};
</script>
